import {
  MembershipPermission,
  checkPermission as rbacCheckPermission,
  checkAnyPermission as rbacCheckAnyPermission,
} from '@/common/rbac';
import { useCallback } from 'react';
import { useMembershipRole } from './MembershipRole';

export const useAuthorization = () => {
  const membershipRole = useMembershipRole();

  /** Return true if the user has the given permissions */
  const checkPermission = useCallback(
    (permission: MembershipPermission | null | undefined) => {
      return rbacCheckPermission(membershipRole, permission);
    },
    [membershipRole]
  );

  /** Return true if the user has any of the given permissions */
  const checkAnyPermission = useCallback(
    (permissions: MembershipPermission[]) => {
      return rbacCheckAnyPermission(membershipRole, permissions);
    },
    [membershipRole]
  );

  return { checkPermission, checkAnyPermission };
};
