import { MembershipRole } from '@/web-types';

export enum MembershipPermission {
  BILLING_READ,
  FEEDING_READ,
  FEED_INVENTORY_READ,
  LOTS_READ,
  MEDICAL_INVENTORY_READ,
  PENS_READ,
  REPORTS_READ,
  SETTINGS_READ,
  SETTINGS_PAGE_VIEW,
}
export const ALL_PERMISSIONS = Object.values(MembershipPermission) as MembershipPermission[];

export const AllRoles: { [name in MembershipRole]: Set<MembershipPermission> } = {
  ADMIN: new Set(ALL_PERMISSIONS),
  OWNER: new Set(ALL_PERMISSIONS),
  USER: new Set([
    MembershipPermission.FEEDING_READ,
    MembershipPermission.FEED_INVENTORY_READ,
    MembershipPermission.LOTS_READ,
    MembershipPermission.MEDICAL_INVENTORY_READ,
    MembershipPermission.PENS_READ,
    MembershipPermission.SETTINGS_PAGE_VIEW, // for logout
  ]),
  FEEDER: new Set([
    MembershipPermission.FEEDING_READ,
    MembershipPermission.SETTINGS_PAGE_VIEW, // for logout
  ]),
  VET: new Set([
    MembershipPermission.MEDICAL_INVENTORY_READ,
    MembershipPermission.SETTINGS_PAGE_VIEW, // for logout
  ]),
  NUTRITIONIST: new Set([
    MembershipPermission.PENS_READ,
    MembershipPermission.LOTS_READ,
    MembershipPermission.FEED_INVENTORY_READ,
    MembershipPermission.MEDICAL_INVENTORY_READ,
    MembershipPermission.FEEDING_READ,
    MembershipPermission.REPORTS_READ,
    MembershipPermission.SETTINGS_PAGE_VIEW, // for logout
  ]),
};
