import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';
import { useIsSuperAdmin } from '../Admin/useIsSuperAdmin';
import { useCallback } from 'react';
import { useAuthorization } from '../hooks/Authorization';
import { NavItem, NavPermissions } from './constants';

const testOrgSet = new Set([
  10, // Jack
  11, // Aurelien
  15, // Demo's
  17, // Ray
  19, // iOS contractor
  25, // Peter
  108, // Warren
  110, // Charles
  112, // Tomas
]);

export const useIsProdWarning = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const { orgId } = useOrgId();
  const isTestOrg = orgId && testOrgSet.has(orgId);
  return !isTestOrg && isSuperAdmin && process.env.NODE_ENV === 'production';
};

export const useGetPermittedNavItems = () => {
  const { checkPermission } = useAuthorization();

  const getPermittedNavItems = useCallback(
    (navItems: NavItem[]) => {
      let items: NavItem[] = [];
      for (const navItem of navItems) {
        const permission = NavPermissions.get(navItem);
        if (checkPermission(permission)) {
          items.push(navItem);
        }
      }
      return items;
    },
    [checkPermission]
  );

  return getPermittedNavItems;
};
