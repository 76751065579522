import { MembershipRole } from '@/web-types';
import { AllRoles, MembershipPermission } from './AllRoles';
import { isPresent } from 'ts-is-present';

/** Return true is the role has the given permission */
export const checkPermission = (role: MembershipRole | null, permission: MembershipPermission | null | undefined) => {
  return isPresent(role) && isPresent(permission) && AllRoles[role].has(permission);
};

/** Return true if the role has any of the given permissions */
export const checkAnyPermission = (role: MembershipRole | null, permissions: MembershipPermission[]) => {
  return permissions.some((permission) => checkPermission(role, permission));
};
