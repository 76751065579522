import FenceIcon from '@mui/icons-material/Fence';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { AccountBalance, BarChartOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { MembershipPermission } from '@/common/rbac';

export interface NavItem {
  label: string;
  href: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}

export const NavPens = { label: 'Pens', href: '/dashboard/pens', Icon: FenceIcon };
export const NavLots = { label: 'Lots', href: '/dashboard/lots', Icon: LocalOfferOutlinedIcon };
export const NavFeedInventory = { label: 'Inventory', href: '/dashboard/inventory', Icon: WarehouseOutlinedIcon };
export const NavMedicalInventory = { label: 'Health', href: '/dashboard/medical-inventory', Icon: MedicalServicesIcon };
export const NavFeeding = { label: 'Feeding', href: '/dashboard/feeding', Icon: RestaurantIcon };
export const NavBilling = { label: 'Billing', href: '/dashboard/billing', Icon: AccountBalance };
export const NavReports = { label: 'Reports', href: '/dashboard/reports', Icon: BarChartOutlined };
export const NavMore = { label: 'More', href: '/dashboard/more', Icon: MenuIcon };

export const NavDesktopMode = { label: 'Desktop', href: null, Icon: FullscreenIcon };
export const NavSwitchYard = { label: 'Switch Yard', href: null, Icon: SyncAltIcon };
export const NavSettings = { label: 'Settings', href: '/dashboard/settings', Icon: SettingsIcon };

export const DESKTOP_NAV_ITEMS: NavItem[] = [
  NavPens,
  NavLots,
  NavFeedInventory,
  NavMedicalInventory,
  NavFeeding,
  NavBilling,
  NavReports,
];
export const DESKTOP_FOOTER_NAV_ITEMS: NavItem[] = [NavSettings];

export const MOBILE_NAV_ITEMS: NavItem[] = [NavPens, NavLots, NavFeeding, NavFeedInventory];
export const MOBILE_MORE_NAV_ITEMS: NavItem[] = [NavMedicalInventory, NavReports, NavSettings];

export const NavPermissions = new Map<NavItem, MembershipPermission>([
  [NavPens, MembershipPermission.PENS_READ],
  [NavLots, MembershipPermission.LOTS_READ],
  [NavFeedInventory, MembershipPermission.FEED_INVENTORY_READ],
  [NavMedicalInventory, MembershipPermission.MEDICAL_INVENTORY_READ],
  [NavFeeding, MembershipPermission.FEEDING_READ],
  [NavBilling, MembershipPermission.BILLING_READ],
  [NavReports, MembershipPermission.REPORTS_READ],
  [NavSettings, MembershipPermission.SETTINGS_PAGE_VIEW],
]);
